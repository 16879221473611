import {APIService} from "@/services/api";
import i18n from "@/plugins/i18n";

export const uniquerUsernameValidator = (value, field, model) => {
  return new Promise(async (resolve, reject) => {
    if (value) {
      try {
        const users = await APIService.get(`users?filter=username||$eq||${value}`);
        if (users.length > 0) {
          if (users[0].id !== model.id) {
            resolve([i18n.t('UserLoginAlreadyExists', {login: value})]);
          }
        } else {
          resolve();
        }
      } catch (e) {
        console.error(e)
        reject()
      }
    }
  });
};
