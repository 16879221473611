import { validators } from "vue-form-generator";
import transforms from "@/plugins/transforms";
import { uniquerUsernameValidator } from "../validations/uniqueUsername";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "userLogin",
      model: "username",
      required: true,
      placeholder: "userLogin",
      max: 128,
      min: 4,
      validator: [
        uniquerUsernameValidator,
        validators.string.locale({
          fieldIsRequired: i18n.t("userLoginRequired"),
          textTooSmall: i18n.t("userLoginMustBeMin{1}"),
          textTooBig: i18n.t("userLoginMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "password",
      label: "userPassword",
      model: "password",
      required: true,
      placeholder: "userPassword",
      max: 128,
      min: 6,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("userPasswordRequired"),
          textTooSmall: i18n.t("userPasswordMustBeMin{1}"),
          textTooBig: i18n.t("userPasswordMustBeLess{1}"),
        }),
      ],
      transforms: [
        transforms.stringToNull,
      ]
    },
    {
      type: "select",
      label: "userRole",
      model: "role",
      required: true,
      selectOptions: {
        noneSelectedText: i18n.t("userChooseRole"),
      },
      values: [
        { name: i18n.t("Administrator"), id: "admin" },
        { name: i18n.t("Moderator"), id: "publisher" },
        { name: i18n.t("Farmer"), id: "farmer" },
        { name: i18n.t("Customer"), id: "customer" },
      ],
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("userRoleRequired"),
        }),
      ]
    },
    {
      type: "select",
      label: "status",
      model: "status",
      required: true,
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("statusRequired"),
        }),
      ],
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("statusRequired"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "userName",
      model: "name",
      required: false,
      placeholder: "userName",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("userNameMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "userTown",
      model: "town",
      required: true,
      placeholder: "userTown",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("userTownRequired"),
          textTooBig: i18n.t("userTownMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "userAddress",
      model: "address",
      required: true,
      placeholder: "userAddress",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("userAddressRequired"),
          textTooBig: i18n.t("userAddressMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "email",
      label: "Email",
      model: "email",
      required: false,
      placeholder: "Email",
      max: 255,
      validator: [
        validators.email.locale({
          textTooBig: i18n.t("userEmailMustBeLess{1}"),
          invalidEmail: i18n.t("userEmailIncorrect"),
        }),
      ],
      transforms: [
        transforms.stringToNull,
      ]
    },
    {
      type: "input",
      inputType: "phone",
      label: "userPhone",
      model: "phone",
      required: true,
      placeholder: "userPhone",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("userPhoneRequired"),
          textTooBig: i18n.t("userPhoneMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "textArea",
      label: "userProfile",
      model: "profile",
      required: false,
      placeholder: "userProfile",
      max: 2048,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("userProfileMustBeLess{1}")
        }),
      ],
      transforms: [
        transforms.stringToNull,
      ]
    },
  ]
};
